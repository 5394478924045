@media (max-width: 550px) {
    .tabs-container-del {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .tabs-del {
        display: flex;
        justify-content: space-between;
        padding: 10px 30px;
        background-color: steelblue;
        color: white;
    }

    .tab-del {
        padding: 10px;
        cursor: pointer;
        /*border: 1px solid #ccc;*/
        border-bottom: none;
        user-select: none;
        color: white;
        font-size: 14px;
    }

    .tab-del.active {
        background-color: white;
        border: 1px solid steelblue;
        color: steelblue;
        font-weight: bold;
        border-radius: 5px;
    }

    .icon-del {
        color: white;
    }

    .icon-del.active {
        color: steelblue;
    }

    .tab-content {
        border: 1px solid #ccc;
        padding: 10px;
        overflow: hidden;
    }

    .content {
        transition: transform 0.3s ease-in-out;
    }

    .content.active {
        transform: translateY(0);
    }

    /*********** For Timer *********/
    .timer-container {
        text-align: center;
    }

    .timer {
        font-size: 2em;
        margin-bottom: 10px;
        color: black;
    }

    .button-container {
        display: flex;
        justify-content: space-around;
    }

    .timer-button {
        margin: 0 5px;
        padding: 10px 10px;
        cursor: pointer;
        border-radius: 5px;
        background-color: white;
        width: 100px;
    }

    .timer-button:disabled {
        cursor: not-allowed;
    }

    /* .timer-button:disabled, i {

        } */
    .Btn1 {
        background-color: dimgray;
        padding: 5px;
        border-radius: 10px;
        width: 100%;
        color: white;
        font-weight: 600;
        border: 3px solid white;
        margin: 0px 5px;
    }

    .timerTrip-modal {
        width: 92%;
        height: auto;
        border: 1px solid rgb(94, 94, 94);
        color: rgb(0, 0, 0);
        background-color: white;
        border-radius: 15px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: modalslide 1000ms;
        padding: 10px;
    }

    .content {
        margin-left: 0px !important;
    }

    .height500px {
        height: 100vh;
        padding: 0px !important;
    }

    .modal-back-del {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.72);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
    }

    .camerabox {
        width: 20%;
        padding: 7px 2px;
        border: 2px dashed rgb(209, 209, 209);
        border-radius: 12px;

    }

    .camerabox i {
        color: rgb(180, 180, 180);
    }

    .display-none-on-500 {
        display: none !important;
    }
}

.hover-enlarge-image {
    width: 100px;
    height: 100px;
    transition: transform 0.3s ease, z-index 0.3s ease;
}

.hover-enlarge-image:hover {
    transform: scale(5);
    /* Scale up the image to 2x its original size */
    z-index: 10;
    position: absolute;
}

.dropdown-content-floating {
    display: block;
    position: absolute;
    background-color: ghostwhite;
    min-width: 215px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.68);
    padding: 0px 0px;
    z-index: 1;
    right: 116%;
}

.dropdown-content-floating div {
    color: black;
    text-decoration: none;
    display: block;
    padding: 15px 15px;
}

.dropdown-content-floating div:hover {
    background-color: #dae9ff;
}

@media (max-width: 1500px) {
    .display-none-on-500 {
        display: block;
    }
}

.td_footer {
    font-weight: 600;
    font-size: 14px;
    height: 50px
}

.table_headerSticky {
    position: sticky !important;
    z-index: 1;
    top: -1px;
    background-color: #5e709e;
    width: 100%;
}