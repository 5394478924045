body .w-5 {
    width: 5.5%;
}

.w-6 {
    width: 6%;
}

.w-7 {
    width: 7%;
}

.ml-10 {
    margin-left: 10px;
}

.btn-red {
    background-color: indianred;
    color: white;
    font-weight: 500;
    justify-content: center;
    padding: 5px;
    border: 1px solid transparent;
    font-size: 14px;
    border-radius: 5px;
    width: 110px;
}

.bottom-div {
    position: absolute;
    /* bottom: 0; */
    /*right: 0;*/
    padding: 15px;
    width: 90%
}

.btn-blue {
    background-color: lightblue;
    color: white;
    font-weight: 500;
    justify-content: center;
    padding: 5px;
    border: 1px solid black;
    font-size: 14px;
    border-radius: 5px;
    width: 130px;
}

.space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.ml-20 {
    margin-left: 20px;
}

.w-100 {
    width: 100%;
}

.w-90 {
    width: 90%;
}

.w-50 {
    width: 50%;
}

.w-30 {
    width: 30%;
}

.w-40 {
    width: 40%;
}

.w-0 {
    width: 0%;
}

.w-8 {
    width: 8%;
}

.firstColumnCellStyle {
    /*background-color: #dee1e6;
    position: sticky;
    left: 0;
    z-index: 1;
    width: 5%;
    font-weight: 500;
    color: black;
    border: 1px solid black;
    display:flex;
    text-align: start;*/
    background-color: #dee1e6;
    color: black;
    padding-left: 12px;
    padding-right: 12px;
}

.headerCellStyle {
    background-color: #dee1e6;
    position: sticky;
    /* top: -2px; */
    z-index: 2;
    width: 8%;
    padding: 5px 0px;
    font-weight: 500;
    color: black;
    /*border: 1px solid grey;*/
}

.tableInputBox {
    width: 70%;
}

.tableBox {
    width: 100%;
    border: 1px solid darkgrey;
}

.tableDiv {
    /* height: 350px; */
    overflow: scroll;
    display: flex;
    justify-content: flex-start;
    /* width: 1200px; */
    /* overflow-y: scroll; */
}

.lensPowerTable {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 5px !important;
    overflow-y: scroll;
}

/*.table-container {
    width: 100%;
    overflow-x: scroll;
}*/
.hover-text2 {
    position: absolute;
    z-index: 1;
    background-color: white;
    color: #3c3c3c;
    padding: 5px;
    font-weight: 600;
    right: 0%;
    /* left: 7%; */
    top: 35px;
    border: 2px solid #a1a0a0;
    border-radius: 5px;
    width: 360px;
}

.TrLensCreation {
    position: relative;
    /* Ensures that the sticky positioning works relative to this container */
}

.TrLensCreation .TrPosiStatus {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
    left: 0;
}

.ButtonStick {
    position: sticky;
    z-index: 1;
    left: 106px;
}

.ButtonStick1 {
    position: sticky;
    z-index: 1;
    left: 0;
}

.ButtonStick2 {
    position: sticky;
    z-index: 1;
    left: 53px;
}

.ButtonStick4 {
    position: sticky;
    z-index: 3;
    left: 199px;
    top: 0px;
}

.hover-text3 {
    position: absolute;
    z-index: 1;
    background-color: white;
    color: #3c3c3c;
    padding: 5px;
    font-weight: 600;
    top: -45px;
    border: 2px solid #a1a0a0;
    border-radius: 5px;
    width: auto;
}

.hover-text4 {
    position: absolute;
    z-index: 1;
    background-color: white;
    color: #3c3c3c;
    padding: 5px;
    font-weight: 600;
    /* right: 6%; */
    left: 15%;
    top: -45px;
    border: 2px solid #a1a0a0;
    border-radius: 5px;
    width: auto;
}

.hoverLeft18 {
    left: 18%;
}

.hoverLeft {
    left: 25%;
}


/* *************************{Tool Tip CSS}**************************** */
.tooltip-container5 {
    position: relative;
    transition: all 0.3s;
    cursor: pointer;
}

.tooltip5 {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.3em 0.6em;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    background-color: rgb(165 163 163);
    border-radius: 12px;
    color: rgb(34, 34, 34);
}

.tooltip5::before {
    position: absolute;
    content: "";
    height: 0.6em;
    width: 0.6em;
    bottom: -0.2em;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    background-color: rgb(255, 255, 255);
}

.tooltip-container5:hover .tooltip5 {
    top: -50%;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    left: 54%;
}

.ToolTipTOp {
    margin-top: 0.5rem !important;
}

.ToolBody {
    display: flex;
}

.toolTextWdth {
    width: 100%;
}

/* **************{TOol Tip CSS}********************* */
.tooltip6 {
    position: relative;
    display: inline-block;
    /* cursor: pointer; */
    transition: all 0.3s;
    margin-left: 12px;
    width: 100px;
    display: flex;
    align-items: center;
}

.tooltip6 button {
    background-color: #db3434;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s ease-out;
}

.tooltip6 button:hover {
    background-color: #b92929;
    transform: scale(1.05);
}

.mode_category:hover ~ .tooltiptext,
.toolTextWdth:hover ~ .tooltiptext { 
    display: block !important;
    opacity: 1;
}


.tooltiptext {
display: none !important;
    width: 120px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 30px;
    left: -72%;
    justify-content: center !important;
    opacity: 0;
    transition: opacity 0.3s;
    transform: translate(50%, 0%);
}

.tooltiptext::after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 46%;
    transform: rotate(0deg);
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;}

.TrLensCreation:hover,
.TrLensCreation:focus-visible .tableBox:focus-visible {
    outline: 2px solid #b1d6fd;
}


.LensOverflow1 {
    width: 88vh;
    overflow-y: scroll;
}

.TabWidthMin {
    min-width: 70px !important;
    text-align: center;
}

.tableBox:hover,
.tableBox:hover,
.tableBox:focus-visible,
.tableBox:focus-visible+.TrLensCreation:focus-visible {
    background-color: #b1d6fd;
}

.tableBox {
    border: 1px solid #e3e3e3;
    width: 100%;
    color: #555;
    font-size: 14px;
    padding: 5px;
    height: 25px;
    word-wrap: normal;
    text-transform: none;
    outline: none;
    border-radius: 0px;
    background: none;
}

/* ************************** */
.focused-row {
    outline: 1px solid rgb(107 129 187);
    z-index: 1;
}

.rightBorder {
    border: 1px solid rgb(69 38 247);
}

@media (max-width: 350px) {
    .space-between {
        display: flex;
        justify-content: space-between;
        /* flex-direction: column; */
    }

    .flex-start {
        padding-top: 0px;
    }

    .flex-d-forbill {
        flex-direction: column;
    }

    .MHut-width-bill {
        width: 15%;
        width: 100%;
    }
}