/*************Sale Voucher List Css****************** */
.svl_input {
  width: 175px;
}

.svl_date_input {
  width: 130px;
}

.svl_icon {
  left: 0rem !important;
}

/*************End Sale Voucher List Css****************** */

/*************User Master List Css****************** */
.search_usermaster {
  display: flex;
}

.col-sm-usermlist {
  display: flex;
  margin-bottom: 0px;
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 8px;
  width: 30%;
}

.input-box-usermlist {
  width: 100%;
}

.grp_usermlist {
  width: 100%;
}

.grp_search_uml {
  width: 70%;
}

.input-box-allotedtbl {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  margin-bottom: 20px;
  height: 20px;
  position: relative;
  width: 193px;
}

/*************End User Master List Css****************** */


/*************End User Activity Css****************** */
.user_activity_panel {
  width: 1%;
}

.search_panel_left_useract {
  width: 80%;
}

.search_panel_left {
  width: 50%;
}

.search_panel_right_useract {
  padding-top: 5px;
  padding-right: 25px;
  width: 25%;
}


/*************End User Activity Css****************** */

/* ************Bill Type Master For CSS****************** */
.table_head_mmbb1 {
  padding-right: 5px;
  font-size: 14px;
  text-align: center;
}

.input-box-mst1 {
  width: 100%;
}

.radio-btm-mn2 {
  width: 54%;
  display: flex;
  justify-content: flex-start;
  padding-right: 82px;
  padding-left: 20px;
}

.mode_tax {
  width: 25%;
  padding-left: 8px;
}

/*.input-box-Bt {
  display: flex;
  justify-content: space-around;
  padding-left: 20px;
  padding-right: 2px;
  margin-bottom: 10px;
   height: 40px; 
  width: 100%;
  position: relative;
}
.input-box-mbt1 {
  width: 49%;
  display: flex;
  justify-content: center;
} */
/* .border_bt{
  border: 1px solid #d1cdcd; 
} */

.BillType_Tax {
  padding-bottom: 15px;
}

/*.region_hght{
  height: 80px;
  display: flex;
  align-items: center;
} */
.region_tax {
  height: 60%;
}

.input-box-Imbt {
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 2px;
  margin-bottom: 10px;
  /* height: 40px; */
  width: 100%;
  position: relative;
}

/*.other_hegt{
  height: 36%;
}
.service_flex{
  display: flex;
  justify-content: flex-end;
}
.mode_top{
  padding-top: 4px;
} */
.radio-btm-specifyhere {
  width: 47%;
  display: flex;
  justify-content: flex-start;
  padding-right: 100px;
  padding-left: 20px;
}

.radio-btn-billspecify {
  color: #7e7d7d;
  font-weight: 600;
  width: 130px;
}

.billtype_input {
  margin-right: 13px;
}

.Border_Region {
  border: 1px solid #d1cdcd;
}

.Radio_Billtype {
  margin-right: 5px;
}

/* ************ End Bill Type Master For CSS****************** */

/*************Delivery Time Slots Settting  Css****************** */
.company_control_DTSS {
  display: flex;
  justify-content: center;
}

/*************End Delivery Time Slots Setting Css****************** */


/*************Sale Voucher List Css****************** */
.svl_input {
  width: 175px;
}

.svl_date_input {
  width: 130px;
}

.svl_icon {
  left: 0rem !important;
}

/*************End Sale Voucher List Css****************** */

/*************User Master List Css****************** */
.search_usermaster {
  display: flex;
}

.col-sm-usermlist {
  display: flex;
  margin-bottom: 0px;
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 8px;
  width: 30%;
}

.input-box-usermlist {
  width: 100%;
}

.grp_usermlist {
  width: 100%;
}

.grp_search_uml {
  width: 70%;
}

.input-box-allotedtbl {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
  margin-bottom: 20px;
  height: 20px;
  position: relative;
  width: 193px;
}

/*************End User Master List Css****************** */


/*************End User Activity Css****************** */
.user_activity_panel {
  width: 1%;
}

.search_panel_left_useract {
  width: 80%;
}

.search_panel_left {
  width: 50%;
}

.search_panel_right_useract {
  padding-top: 5px;
  padding-right: 25px;
  width: 25%;
}


/*************End User Activity Css****************** */


/*************Delivery Time Slots Settting  Css****************** */
.company_control_DTSS {
  display: flex;
  justify-content: center;
}

/*************End Delivery Time Slots Setting Css****************** */


/*************Member Card Css****************** */
.membercard_top {
  padding-top: 25px;
}

.membercard_modal {
  width: 70%;
}

.increase_modal {
  width: 70%;
  /* height: 82vh; */
}

.Modal-Display {
  display: flex;
  justify-content: flex-end;
}

.close_modal {
  position: absolute;
  color: #aaaaaa;
  float: right;
  font-size: 24px;
  top: 3px;
  font-weight: bold;
  cursor: pointer;
  right: 21px;
}

.close_modal:hover {

  color: red;
}

.close_modal-attribute:hover {

  color: red;
}

.close_modal-category:hover {

  color: red;
}


/*************End Member Card Css****************** */

/*************for modal box Css****************** */
.control_flex_modal {
  width: 98%;
  /* display: flex; */
}

.tab-content-modal {
  width: 100%;
  margin-top: 15px;
  display: flex;
  height: 570px;
  overflow: scroll;
  scrollbar-width: none;
}

.tab-content-modal::-webkit-scrollbar {
  display: none;
}

.company-info-top-modal {
  /* width: 50%; */
  margin: 10px;
  padding: 0px;
}

/*************for modal box Css****************** */




/*************New CSS****************** */
/*************New CSS****************** */
/*************New CSS****************** */


/*************Sale Invoice Css****************** */

.cont_prins {
  width: 163px !important;
}

.apply_check {
  width: 150px !important;
}

.item_increase {
  width: 100% !important;
  height: 100% !important;
}

.prs-ad {
  margin-left: 3px;
}

.AR-Prs {
  margin-left: 6px;
}

.IM-Prs {
  margin-left: 8px;
}

/*************End Sale Invoice Css****************** */


/*************Modal Order List Css****************** */

.table_head_OrderList {
  display: flex;
  /* width: 105px; */
  justify-content: center;
}

.table_head_OrderList_MG {
  display: flex;
  /* width: 105px; */
  justify-content: flex-start;
}

.table_tbody_Order_List {
  border-top: 1px solid #e3e1e1;
  height: 30px;
}

.table_tbody_Order_List:hover {
  background-color: #e1effd;
}

.thead_scroll_orderlist {
  height: 32px;
  color: white;
}

.check_box_order {
  width: 14px;
  height: 14px;
  margin-top: 6px;
}

.modal-OrderLIst {
  color: #df1a1a;
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  padding-top: 10px;
}

.input_odr {
  height: 32px;
}

.ORD_btn {
  height: 30px;
}

.search-button-main {
  width: 85px;
  /* height: 31px; */
  background: white;
  padding: 5px 0px;
  border: 1px solid grey;
  color: black;
  border-radius: 8px;
  font-weight: 100;
  transition: all;
  transition-duration: 300ms;
}

.search-button-main-search {
  width: 85px;
  /* height: 31px; */
  background: #e59012;
  padding: 5px 0px;
  border: none;
  color: white;
  border-radius: 8px;
  font-weight: 100;
  transition: all;
  transition-duration: 300ms;
}

.search-button-main-search:hover {
  background: #ff9900;
  color: white;
  cursor: pointer;
}

.search-button-main:hover {
  background: #975cb3;
  color: white;
  cursor: pointer;
}

.search-button-main:hover i {
  color: white;
}

.input-order-buttons {
  display: flex;
  width: 100%;
  padding-top: 20px;
}

.modalBoxOrderList {
  width: 50% !important;
}

.table_ord_mod {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  /* height: 100%; */
  --scrollbar-thumb-bg: #888;
  --scrollbar-hover-bg: #555;
  --moz-scrollbar-bg: #fff;
}

.Check_Box_head {
  display: flex;
  justify-content: center;
}

.mid-size-orderlist {
  width: 102px;
}

.Ord_Box_OD {
  width: 113px;
}

/* .btn_ord_pad{
  padding-left: 9px;
} */

.input_RMK {
  padding-left: 8px;
}

.modal_level_ordlist {
  width: 50%;
}

/*************End Modal Order List Css****************** */


/*************Recipe Management Css****************** */

.container-Recipe {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f6f9ff;
  height: 98vh;
}

.container_main-RcpMgmt {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  /* margin-top: 10px; */
  padding-left: 13px;
}

.container_Recipe-Management {
  border-radius: 10px;
  width: 99%;
  border: 2px solid #e5efff;
  height: 100%;
  text-align: initial;
  background: white;
  margin-right: -8%;
  /* overflow-x:  hidden ;
  overflow-y: scroll; */
}

.Recipe_right_top {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  padding: 10px;
  position: fixed;
  /* border-bottom: 1px solid #e5efff; */
  background-color: white;
  z-index: 1;
}

.Recipe-button-main {
  /* width: 120px; */
  height: 35px;
  background: rgb(231, 231, 231);
  padding: 5px 10px;
  border: 1px solid grey;
  color: black;
  border-radius: 8px;
  font-weight: 500;
  transition: all;
  transition-duration: 300ms;
}

.NewRecipe-button-main-search {
  /* width: 145px; */
  height: 35px;
  background: #d60f0f;
  padding: 5px 10px;
  border: none;
  color: white;
  border-radius: 8px;
  font-weight: 500;
  transition: all;
  transition-duration: 300ms;
}

.ItemRecipe-button-main-multiple {
  /* width: 175px; */
  height: 35px;
  background: rgb(231, 231, 231);
  padding: 5px 10px;
  border: 1px solid grey;
  color: black;
  border-radius: 8px;
  font-weight: 500;
  transition: all;
  transition-duration: 300ms;
}

.NewRecipe-button-main-search:hover {
  background: #ff9900;
  color: white;
  cursor: pointer;
}

.Recipe-button-main:hover {
  background: #975cb3;
  color: white;
  cursor: pointer;
  scale: 1.05;
  box-shadow: 2px 4px 6px rgb(202, 202, 202);
}

.ItemRecipe-button-main-multiple:hover {
  background: #975cb3;
  color: white;
  cursor: pointer;
  scale: 1.05;
  box-shadow: 2px 4px 6px rgb(202, 202, 202);
}

.Recipe-button-main:hover i {
  color: white;
}

.ItemRecipe-button-main-multiple:hover i {
  color: white;
}

.Recipe-flex-start {
  display: flex;
  justify-content: flex-start;
  /* margin-top: 10px; */
}

.fa-plus:hover {
  color: #fffffe;
}

.Recipe_Select_Item {
  padding-top: 67px;
  display: flex;
  flex-direction: column;
  /* background-color: #e5e5e5; */
  background-color: #e1effd;
  margin-top: 1rem !important;
  padding-bottom: 5px;
  border-radius: 10px;
}

.mode_Recipe {
  width: 50%;
  padding-left: 8px;
}

.stock-recipe-MGM {
  width: 100%;
  display: flex;
}

.input-flex-recipe {
  display: flex;
  justify-content: flex-start;
  padding-right: 0px;
}

.recipe_bgcolor {
  background-color: #f5f5f5;
  /* background-color: #eaeaea; */
}

.table_hd_recipe {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  /* height: 100%; */
  --scrollbar-thumb-bg: #888;
  --scrollbar-hover-bg: #555;
  --moz-scrollbar-bg: #fff;
}

.td_col_Recipe {
  /* padding-left: 12px; */
  font-size: 14px;
  text-align: center;
}

.table-border_RCP {
  border: 0px solid #ddd;
}

.table_Action {
  width: 10%;
}

.thead_scroll_Recipe {
  height: 30px;
}

.table_head_RCP_MGM {
  display: flex;
  justify-content: center;
}

.table_tbody_tr_RCPMG {
  height: 35px;
}

.table-tbody_RecipeMgm {
  box-shadow: inset 0px -1px 0px #eee, 0px 5px 5px -5px rgba(0, 0, 0, .1) !important;
  position: relative !important;
  /* background-color: #5e709e; */
  background-color: #e1effd;
  color: #494848;
}

.thead_scroll_RecipeManage {
  height: 45px;
}

.table_tbody_Recipe {
  border-top: 1px solid #e3e1e1;
  height: 45px;
}

.modified_flex {
  display: flex;
  justify-content: center;
}

.Action_Buttons {
  display: flex;
  justify-content: center;
  padding-top: 8px;
}

.modified_top {
  margin-top: 15px;
}

.delete_btn_RCP {
  margin: auto;
  border: none;
  padding-top: 4px;
}

.Recipes_Text {
  padding-top: 15px;
  padding-left: 10px;
  font-size: 15px;
  color: #48a2a2;
}

.border_hide {
  outline: none;
  border: none;
  border-bottom: 1px solid #cfcfcf;
}

.Recipe_footer {
  display: flex;
  justify-content: flex-start;
  bottom: 0;
  width: 90%;
  padding: 11px;
  position: fixed;
  border-bottom: 1px solid #e5efff;
  background-color: #ecdddd;
  border-radius: 10px;
  z-index: 1;
}

.Recipes_Footer_Text {
  padding-top: 8px;
  padding-left: 2px;
  font-size: 15px;
  color: #7c7e7e;
  font-weight: 600;
}

/*************End Recipe Management Css****************** */

/*************Add Recipe Css****************** */
.input-AddSelect-Box {
  width: 50%;
}

.container_Recipe-Add {
  border-radius: 10px;
  width: 99%;
  border: 2px solid #e5efff;
  height: 100%;
  text-align: initial;
  background: white;
  margin-right: -8%;
  /* overflow-x:  hidden ;
overflow-y: scroll; */
}

.Recipe_change_Item {
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  /* background-color: #e5e5e5; */
  background-color: #e1effd;
  margin-top: 1rem !important;
  border-radius: 10px;
}

.input-flex-Addrecipe {
  display: flex;
  justify-content: flex-end;
  padding-right: 9px;
}

.Recipe_radius_add {
  padding-bottom: 15px;
  width: 96%;
  /* height: 100%; */
  /* border: 1px solid #ccc; */
  margin: auto;
  margin-top: 0px;
  border-radius: 8px;
  box-shadow: 0 0 5px 3px rgb(175 170 170 / 38%);
}

.RCP_flex {
  display: flex;
  justify-content: space-between;
}

.Recipe_PAV_head {
  width: 96%;
  margin: auto;
  border-radius: 5px;
}

.head_RCP_brd {
  padding: 8px !important;
  border: none !important;
}

.Add_rcp_td {
  border: none !important;
  text-align: center !important;
}

.Recipe_ADD_flex-end {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}

.input-height_Recipe {
  padding-top: 22px;
}

.recipe_add_height {
  height: 145px;
}

.Add_Recipe_top {
  display: flex;
  justify-content: flex-end;
  width: 86%;
  padding: 10px;
  /* border-bottom: 1px solid #e5efff; */
  background-color: white;
  z-index: 1;
}


/*************Add Recipe Css****************** */


/*************Purachase Invoice Css****************** */

.Purchase-invoice-table {
  width: 100%;
  padding-top: 5px !important;
}

.pur-body-width {
  width: 50%;
}

.input-pur-invoice {
  width: 48%;
}

.Right-Pur-Flex {
  display: flex;
  flex-wrap: wrap;
}

.bill-item-btn {
  padding-right: 10px;
}


/*************End Purachase Invoice Css****************** */


/*************Stock Out Css****************** */
.input-box-Stockout {
  width: 28%;
  display: flex;

}

.copy_trasn_btn {
  height: 25px;
  margin-top: 4px;
}

.input-stk-copy {
  height: 32px;
}

.input-copy-Trans {
  padding-top: 8px;
}

.input_copy_stock {
  padding-left: 8px;
  width: 100%;
}

.tabs-main_stock {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.flex_stock_copy {
  justify-content: flex-end;
}

.stock-out-padding {
  padding-right: 5px;
  flex-wrap: wrap;
}

.tab_stock_remark {
  /*width: 45%;*/
  width: 60%;
}

.stock-flex-start {
  display: flex;
  justify-content: flex-start;
}

.stock_padding {
  padding: 0px !important;
  padding-left: 10px !important;
}

.content-stock-height {
  height: 123px;
  padding: 5px;
}

.StockOut_Copy {
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
}

.particular_font {
  font-weight: 900;
}

.content_godown_height {
  height: 155px;
  padding: 5px;
}

.input-stock-bottom {
  margin-bottom: 2px !important;
  padding-left: 2px;
}

.stock-margin-top {
  margin-top: 6px;
}

.flex-wrap-stock {
  margin-bottom: 7px;
}

/*************End stock Out Css****************** */



/*************Pay In Pay Out For css****************** */

.flex-wrap-payin {
  flex-wrap: wrap;
  background-color: white;
}

.PayIn-Box {
  width: 12%;
  background-color: #f0f1fa;
  padding: 20px;
  margin-top: 20px;
}

.Flex-Center-Pay {
  display: flex;
  justify-content: center;
}

.PayIn-margin {
  margin-left: 10px;
}

.PayIn-Head-Text {
  width: 98%;
  margin-left: 13px;
}

/*************Eye Check Up For css****************** */

.table-width-eye {
  width: 100%;
  /* padding-right: 5px; */
}

.Flex-Lens-Display {
  display: flex;
}

.si-size-Eye {
  width: 100%;
}

.col-eye-check {
  width: 18% !important;
}

.DV-input-Eye {
  width: 18% !important;
}

.tab-eye {
  width: 7%;
}

.content-eyecheck-height {
  height: 145px;
  padding: 5px;
}

.display-flex-eyecheck {
  display: flex;
}

.Trans-Margin-Top {
  margin-top: 10px;
}

/* .tab-RL-eye{
width: 25% !important;
} */

.Print-width-DV {
  width: 32%;
}

.NV-CL-Width {
  width: 70% !important;
}

.input-box-EyeCheckup {
  width: 15%;
  display: flex;
}

.Show_Btn {
  width: 70%;
  height: 27px;
}

.tab-Height {
  height: 35px !important;
}

.tab-Lens {
  height: 35px !important;
}

.active-eye {
  background-color: #344263;
  color: white;
}

.REye {
  margin: auto;
}

/*************Clinic Master For css****************** */

.clinic-text-area {
  background-color: white;
}

.input-clinic-search {
  width: 100%;
}

.content-clinic-height {
  height: 60px;
  padding: 5px;
}

.input-flex-clinic {
  display: flex;
  justify-content: flex-start;
  padding-right: 9px;
}

.company-info-top-right-clinic {
  width: 100%;
  padding: 22px;
}


/*************Add/Update Form Name****************** */

.td-description-width {
  width: 22%;
}

.td-edit-width {
  width: 5%;
}

.search-add-width {
  width: 49%;
}

.config-search-top {
  margin-top: 20px;
  padding-top: 15px !important;

}

.configure_radius_add_update {
  padding: 15px 10px 5px 10px;
  width: 98%;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: auto;
  margin-top: 5px;

}

.td_space {
  padding: 4px 5px;
}

.company-info-top-left11 {
  /* border-right: 1px solid #d1cdcd; */
  justify-items: initial;
  width: 100%;
  padding: 22px;
  box-shadow: 2px 2px 15px rgb(212, 212, 212);
}

.left-side-tre5 {
  left: 55%;
}

.table-scrol12 {
  table-layout: fixed !important;
  border-collapse: collapse !important;
  margin: 0 auto !important;
  border: 1px solid #eee !important;
  width: 100%;
}

.td-col33 {
  width: 60%;
  color: white;
}

.table-th-22 {
  text-align: center;
  padding-right: 0px;
  width: 11%;
}