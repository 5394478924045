body {
    background: #fff;
    font-family: 'Calibri', sans-serif !important;
    color: #4e4e4e;
    line-height: 22px;
}

.attendance-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.th, .td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    font-family: Calibri !important;
    padding: 15px;
}

.th {
    background-color: #afbbdd;
    text-align: center;
}


select {
    background-color: white;
}

.greenBtn {
    background-color: #2a9f79;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    color: white;
    font-weight: 600;
    border: 3px solid white;
}

    .greenBtn:hover {
        background-color: #1f7559;
        color: white;
    }

.redBtn {
    background-color: #ff4163;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    color: white;
    font-weight: 600;
    border: 3px solid white;
}

    .redBtn:hover {
        background-color: #a42b40;
        color: white;
    }
    .greenBtn button {
    background-color: #ececec !important;
}

.userDetail-modal {
    width: 25%;
    height: auto;
    border: 1px solid rgb(94, 94, 94);
    color: rgb(0, 0, 0);
    background-color: white;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: modalslide 1000ms;
    padding: 10px;
}

.modal-back2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.72);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.ques {
    font-weight: 700;
    font-size: 14px;
    padding: 2px 0px;
    color: black;
}

.ans {
    /*margin-left: 5px;*/
    font-weight: 400;
    font-size: 14px;
    padding: 2px 0px;
    color: black;
}

.sticky-column {
    position: sticky;
    left: 0;
    background-color: #f2f2f2;
    /*  border-right: 2px solid #a9a9a9 !important;
    border-left: 2px solid #a9a9a9 !important;
    border-bottom: 2px solid #a9a9a9 !important;
    border-top: 2px solid #a9a9a9 !important;*/
}

.company-info-top-bottom {
    margin:20px;
}

.tableSelectBox {
    border-radius: 5px;
    border: 1px solid lightGrey;
    padding: 5px
}

.tableHeader {
    padding: 15px;
    border: 1px solid darkgrey;
    color: black;
    background-color: #f2f2f2;
}

.newRowBtn {
    border-radius: 5px;
    padding: 5px;
    background-color: #ececec;
    color: white;
}

.monthly-attendance-report {
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
}

.days-of-week {
    display: flex;
}

.day {
    flex: 1;
    padding: 8px;
    text-align: center;
    border: 1px solid #ccc;
}

.present {
    background-color: #b2f5b2;
}

.absent {
    background-color: #ffb2b2;
}

.sunday {
    background-color: #e3c004;
}


.flex {
    display: flex;
}

.space-evenly1 {
    display: flex;
    justify-content: space-evenly;
}

.inputTime {
    box-shadow: none !important;
    border-radius: 5px;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
}

.panel-heading1 {
    justify-content: space-between;
    display: flex;
    background-color: rgb(33, 46, 79);
}

.panel-head-title1 {
    width: 50%;
}

.panel-head-button1 {
    /* width: 50%; */
    padding-right: 10px;
    padding-top: 7px;
}

.panel-title1 {
    padding-top: 20px;
    height: 40px;
    line-height: 6px;
    font-size: 20px;
    align-items: center;
    padding-left: 32px;
    color: white;
    font-weight: 600;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.flex-start1 {
    display: flex;
    justify-content: flex-start;
}

.allowanceDiv {
    border: 1px solid lightgrey;
    border-radius: 10px;
}

.display-full {
    display: block;
}

.display-550 {
    display: none;
}
/******************** Media Query 1380px **************************/
@media(max-width:1380px) {

    .th {
        padding: 5px;
    }

    .td {
        padding: 0px 5px;
    }
}

/******************** 1150px **************************/
@media(max-width:1150px) {
    .th {
        padding: 5px;
    }

    .td {
        padding: 4px;
    }

    .display-1150 {
        display: none;
    }

    .display-550 {
        display: block;
    }
}

/******************** 950px **************************/
@media(max-width:950px) {
    .th {
        padding: 5px;
    }

    .td {
        padding: 4px;
    }

    .display-1150 {
        display: none;
    }

    .display-550 {
        display: none;
    }
}
/******************** 750px **************************/
@media(max-width:1150px) {
    .th {
        padding: 5px;
    }

    .td {
        padding: 4px;
    }

    .display-1150 {
        display: none;
    }

    .display-550 {
        display: none;
    }

    .ques {
        font-size: 14px;
    }

    .ans {
        font-size: 14px;
    }

    .space-evenly1 {
        display: grid;
        justify-content: space-around;
    }

    .filterBox {
        padding: 3px;
        border-radius: 5px;
        border: 1px solid lightgrey;
    }
}
/******************** 700px **************************/
@media(max-width:700px) {
    .mobNo {
        display: none;
    }

    .designation {
        display: none;
    }

    .ques {
        font-size: 14px;
    }

    .ans {
        font-size: 14px;
    }
}
/******************** 550px **************************/
@media(max-width:550px) {
    .display-full {
        display: none;
    }

    .display-550 {
        display: block;
    }

    .userDetail-modal {
        width: 95%;
    }

    .space-evenly1 {
        justify-content: normal;
        align-items: center;
    }

    .attendanceBody {
        padding: 0px 10px;
    }

    .attendanceDiv {
        border: 1px solid lightgrey;
        padding: 10px;
        margin: 5px;
        border-radius: 10px;
        background-color: white;
    }

    .inputTime {
        box-shadow: none !important;
        border-radius: 5px;
        width: 100px;
        border: 1px solid lightgrey !important;
        background-color: white;
        padding: 0px;
    }

    .filterBox {
        width: 100%;
    }
    /*
    .attendanceBtn{
        border:1px solid lightgrey;
        background-color:ghostwhite;
        border-radius:2px;
        display:flex;
        justify-content:center;
        align-items:center;
        padding:5px 15px;
        margin:5px
    }
    .attendanceBtn:active{
        background-color:red;
    }*/

}

/******************** 480px **************************/
@media(max-width:480px) {
    .s-e-time {
        /*width:50%;*/
        padding: 10px;
    }

    .flex-start1 {
        display: grid;
        align-content: center;
        justify-content: center;
    }

    .panel-heading1 {
        width: 105%;
    }
}
