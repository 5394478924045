@media(max-width:2500px) {
  .col-sm-3 {
    width: 7.5% !important;
  }

  .col-sm-4 {
    width: 10.4% !important;
  }

  .table-input1 {
    width: 16.5% !important;
    font-size: 12px;
    padding: 3px;
  }

  .modal {
    display: block !important;
  }

  .height-200 {
    height: 130px !important;
  }

  .tab-content-box {
    overflow-y: scroll;
  }

  .tab-content-box::-webkit-scrollbar {
    display: none;
  }

  .tab-content-box {
    scrollbar-width: none;
  }
}

@media(max-width:1800px) {

  .height-200 {
    height: 130px !important;
  }
}

@media(max-width:1450px) {
  .tab-content-box {
    overflow-y: scroll;
  }

  .height-200 {
    height: 119px !important;
  }
}

@media(max-width:1350px) {

  .height-200 {
    height: 118px !important;
  }

  .labelEffect1 {
    font-size: 14px !important;
  }

  .tab-content-box {
    overflow-y: scroll;
  }
}

@media(max-width:1200px) {
  .height-200 {
    height: 150px !important;
  }

  .height22vh {
    height: 15vh;
  }

  .label10px {
    font-size: 10px !important;
  }

  .alignCenter {
    display: block !important;
    padding-left: 10px;
  }

  .radio-btn-1 {
    padding-right: 0px !important;
  }

  .fw-10 {
    font-size: 10px !important;
  }

  .w-130 {
    width: 140% !important;
  }
}

@media(max-width:1145px) {
  .tab-btn-box2 {
    font-size: 9px !important;
  }

  .fw-12 {
    font-size: 12px !important;
  }

  .w-130 {
    width: 200%;
  }
}

/* @media(max-width:1176px) {
 
  .radio-btn-1{
    padding-right:0px !important;
  }
} */

@media (max-width: 1050px) {
  .group {
    padding-right: 50px;
  }

  .alignCenter {
    display: flex;
    justify-content: flex-start;
    align-content: center !important;
    align-items: center !important;
  }
}

@media (max-width: 950px) {

  .item-info-top {
    display: flex;
  }

  .modify_lbl_text4 {
    left: 56% !important;
  }

  .modify_lbl_text2 {
    left: 55.5% !important;
  }

  .modify_p_text-fix {
    left: 8% !important;
  }

  .input_box_double {
    display: flex;
    width: 98%;
  }

  .res_s1 {
    left: 53% !important;
  }

  /* .modify_lbl_text2{
 transform: translateY(-10%) scale(0.8) !important;
 position: absolute;
 transition: 150ms cubic-bezier(0.4,0,0.2,1);
 top: -10px;
} */
  .modify-input-box-part-2 {
    padding-left: 1px !important;
  }

  .modify_lbl_text3 {
    left: 7% !important;
  }

  .company-info-top-left2 {
    width: 95% !important;
    padding-top: 20px;
  }

  .table {
    width: 100% !important;
    margin-bottom: 2px;
  }

  .table-th {
    font-size: 11px !important;
  }

  .table-td {
    width: 10px;
  }

  .company-info-top1 {
    display: contents !important;
  }

  .col-3 {
    width: 30% !important;
  }

  .group-1 {
    width: 100% !important;
  }

  .col-sm-3 {
    width: 17% !important;
  }

  .content-row-1 {
    display: none;
  }

  .size_box {
    width: 100%;
  }

  #display_down {
    padding-left: 1px !important;
    width: 99% !important;
  }

  .payment-row {
    width: 100% !important;
  }

  .upi-payment-main {
    width: 100%;
  }

  .m5 {
    font-size: 12px;
  }

  .upi-pay-text {
    width: 55% !important;
  }

  .col-sm-12 {
    padding-right: 25px;
  }

  .input-box1 {
    margin-bottom: 23px !important;
    height: 30px !important;
  }

  .input-boxupi {
    margin-bottom: 23px !important;
    height: 30px !important;
  }

  .group {
    padding-right: 60px;
    position: inherit;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
  }

  .buttom-box {
    display: none;
  }

  .panel-time {
    display: none;
  }

  .company_info-text {
    display: none;
  }

  .company-info-top {
    margin: auto;
  }

  .user_label-1 {
    position: absolute;
    left: 3%;
    color: #958a8a;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    top: 0px;
  }

  .input:focus~label,
  input:valid~label {
    transform: translateY(-50%) scale(0.8);
    /* background-color: #ffffff; */
    padding: 0 0.2em;
    /* color: #2196f3; */
  }

  /* input:valid ~ label {
    transform: translateY(-50%) scale(0.8) !important;
    background-color: #f5f5f56c !important;
    padding: 0 0.6em;
    color: #696969 !important;
    font-size: 16px;
  } */
  .input_text:focus,
  input:valid {
    outline: none;
    border: 1.5px solid #abadaf;
    /* box-shadow: 1px 1px 1px #aba69c; */
    font-size: 14px;
  }

  .modify_product_input {
    font-size: 20px;
    height: 35px !important;
  }

  .panel-heading {
    margin-top: 0px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #212e4f !important;
  }

  .panel-head-button {
    width: 50% !important;
  }

  .panel-title {
    background-color: #fafafa00 !important;
    font-weight: 600 !important;
    font-size: 17px !important;
    margin: 0% !important;
    color: #2e2e2e !important;
  }

  .input-container {
    display: block !important;
    width: 90%;
    margin: auto;
    height: 50px;
    margin-top: 43px;
  }

  .input:valid~label {
    transform: translateY(-50%) scale(0.9) !important;
    background-color: #ffffff !important;
    padding: 0 0.6em;
    color: #696969;
    font-size: 16px;
  }

  .input-box {
    display: none !important;
  }

  .panel-title {
    line-height: 25px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .company-info-top {
    width: 100%;
  }

  .col_1 {
    width: 100%;
    /* padding-left: 336px; */
    margin: auto;
    /* position: fixed; */
    bottom: 15px;
  }

  .content_box {
    border: none !important;
    box-shadow: none !important;
    width: 100% !important;
    height: 100%;
    padding-bottom: 0px !important;
  }

  .reset_btn {
    margin-right: 0px !important;
  }

  .company-info-top-left {
    justify-items: initial;
    width: 50% !important;
    /* background: #ffffff; */
    padding-bottom: 40px;
    /* box-shadow: 2px 2px 15px rgb(212, 212, 212); */
  }

  .modify_product_input {
    font-size: 15px;
  }

  .content_box {
    margin-top: 5px !important;
  }

  .company_info-text {
    font-size: 19px !important;
    text-align: left;

    margin: 15px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .company-info-top {
    margin-top: 0 !important;
  }

  .btn-sucess {
    width: 55px !important;
  }

  .col-sm-1 {
    width: 25%;
  }

  .col-sm-2 {
    width: 40%;
  }

  /* .t1:focus,
  input:valid {
    outline: none !important;
    border: 1.5px solid #e7dede;
  } */

  .user-label {
    position: absolute;
    left: 5%;
    color: #413b3b;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .t1:focus~label {
    transform: translateY(-50%) scale(0.8);
    background-color: #ffffff00;
    padding: 0 0.2em;
    color: #000000;
  }

  .modify_p_text {
    height: 31px;
  }

  .upi-pay-side {
    height: 40px;
  }

  .table-tb {
    font-size: 13px;
  }
}

@media (max-width: 550px) {
  .input-box-m11 {
    width: 100% !important;
  }

  .w-130 {
    width: 100% !important;
  }

  .HeadContentTitle {
    flex-direction: column !important;
  }

  .input_box_double {
    width: 100% !important;
  }

  .w-28 {
    width: 80%;
  }

  .MultiAlias-modal {
    width: 95% !important;
  }

  .td-1 {
    justify-content: space-between !important;
    padding: 6px 22px !important;
  }

  .modifyTextLabel6 {
    left: 19px !important;
    top: 4px !important;
  }

  .modifyTextLabel {
    left: 20px !important;
    top: 4px !important;
  }

  .DirectFlex {
    padding-top: 16px !important;
  }

  .ModalBoxItem_FullHeight {
    display: block !important;
  }

  .cl-toggle-switch {
    padding-right: 0px !important;
  }

  .modal-content-im {
    padding-top: 0px !important;
    border-top: none !important;
  }

  .modal-content2 {
    width: 90% !important;
  }

  .ModalBoxItem_Left {
    width: 100% !important;
  }

  .ModalBoxItem_Right {
    width: 100% !important;
  }

  .app-container {
    display: block !important;
  }

  .right-container {
    margin-left: 0px !important;
  }

  .left-container {
    display: none !important;
  }

  .small-sidebar {
    display: none !important;
  }

  .dm_3 {
    padding-right: 10px !important;
  }

  .modify_lbl_text11 {
    left: 77% !important;
    top: 62px !important;
  }

  .dm_4 {
    padding-top: 12px !important;
  }

  .modify_im_input17 {
    width: 97% !important;
    height: 25px !important;
  }

  .modify_label_text5 {
    top: -4px !important;
  }

  .ssm {
    display: flex;
    justify-content: center !important;
  }

  .hbn1 {
    width: 25% !important;
  }

  .hbn2 {
    width: 50% !important;
  }

  .search-box-btn-1 {
    width: 25% !important;
  }

  .stock-im-2 {
    width: 100%;
  }

  .modify_p_text2 {
    left: 31px !important;
  }

  .modify_label_text2 {
    left: 36px;
    top: -3px !important;
  }

  .modify_label_text3 {
    left: 31px;
    top: -4px !important;
  }

  .input_block {
    display: none;
  }

  .sol1 {
    width: 60px !important;
    height: 20px;
    padding: 0px 10px;
    position: absolute;
    top: -10px;
    left: -16px;
    font-size: 13px !important;
  }

  .input-height_h1 {
    height: 25px !important;
  }

  .input-height7 {
    width: 100%;
    margin-bottom: 23px !important;
    flex-direction: column !important;
  }

  .col-hm4 {
    width: 50%;
  }

  .head_input_hhm {
    height: 56px !important;
  }

  .padding-03 {
    padding-right: 10px !important;
  }

  .col-hm3 {
    width: 30%;
    display: contents;
  }

  .padding-05 {

    padding-right: 10px !important;
  }

  .mode_h1 {
    width: 98% !important;
  }

  .table-down-m1 {
    width: 15% !important;
  }

  .table-down-s1 {
    width: 100% !important;
    display: flex;
    height: 30px !important;
    justify-content: center !important;
  }

  .modal-content1 {
    margin-top: 205px !important;
  }

  .container_over {
    overflow: auto;
    height: 100% !important;
  }

  .input-remark-001 {
    width: 100%;
    display: flex;
    align-items: baseline;
    padding-top: 5px;
  }

  .input-remark-left {
    width: 100% !important;
  }

  .input-size-04 {
    width: 30%;
  }

  .input-size-01 {
    width: 80% !important;
  }

  .input-remark-box-m1 {
    width: 50% !important;
  }

  .company-info-top-left-tab5 {
    width: 100% !important;
  }

  .company-info-top-right-tab5 {
    width: 100% !important;
  }

  .tabs-table1 {
    width: 96% !important;
  }

  .mid-size-01 {
    width: 48% !important;
  }

  .sol5 {
    width: 200px !important;
  }

  .modify_lbl_text12 {
    top: 56px !important;
  }

  .col-s5 {
    padding-top: 10px !important;
  }

  .ffm4 {
    flex-direction: column;
  }

  .input-box-m9 {
    width: 100% !important;
    align-items: center;
    position: relative;
  }

  .check_box_b1 {
    align-items: baseline;
    padding-top: 13px;
  }

  .mod_p1 {
    margin-top: 120px !important;
  }

  .input-height3 {
    height: 50px !important;
  }

  .col_ffn1 {
    margin-top: 10px !important;
  }

  .value_12 {
    font-size: 11px !important;
  }

  .hhm1 {
    height: 40px;
    align-items: baseline;
  }

  .input-remark-01 {
    padding-bottom: 50px !important;
  }

  .bmm_5 {
    padding-top: 5px;
    width: 100% !important;
  }

  .tabs-main1 {
    flex-direction: column !important;
  }

  .tab_a1 {
    width: 100% !important;
  }

  .input-remark-01 {
    flex-direction: column !important;
  }

  .table-row1 {
    width: 100% !important;
  }

  .table-down-s1 {
    padding-right: 0px !important;
  }

  .table-down-l2 {
    font-size: 13px !important;
  }

  .label-input-sa1 {
    top: -12px !important;
  }

  .m-26 {
    top: -8px !important;
  }

  .iim {
    align-items: baseline;
  }

  .radio-btn-1 {
    align-items: center !important;
    padding-top: 0px;
  }

  .radio-btn-2 {
    align-items: flex-start !important;
    padding-top: 0px;
  }

  .si-size-2 {
    width: 100% !important;
    margin-top: 10px;
  }

  .input-j1 {
    margin-left: 136px !important;
  }

  .modify_p_2 {
    width: 75% !important;
    padding-left: 0px !important;
  }

  .input-height4 {
    /* height: 2px !important; */
    margin-bottom: 23px !important;
  }

  .mt-2 {
    margin-top: 0rem !important;
  }

  .col-sm-3 {
    width: 10% !important;
  }

  .modify_lbl_text10 {
    left: 54% !important;
  }

  .m-24 {
    top: -4px !important;
    left: 55.5% !important;
  }

  .table-right-padding {
    width: 100% !important;
  }

  .tabs-main {
    width: 100%;
    flex-direction: column;
  }

  .si-size-1 {
    margin-top: 0px;
    width: 100% !important;
  }

  .input-box-head-fm1 {
    flex-direction: column !important;
  }

  .table-left-padding {
    width: 100% !important;
  }

  .modal-content {
    margin: 40% auto !important;
  }

  .radio-btm-m3 {
    width: 49% !important;
  }

  .modify_lbl_text10 {
    left: 55% !important;
    top: -4px !important;
  }

  .col-1 {
    width: 61% !important;
  }

  .modify_lbl_text4 {
    left: 7% !important;
    top: 55px !important;
  }

  .modify_im_textarea {
    width: 97% !important;
  }

  .modify_p_text-fix {
    left: 8% !important;
  }

  .modify_lbl_text3 {
    left: 6.5% !important;
    top: -8px !important;
  }

  .ffd {
    display: flex;
    flex-direction: column;
  }

  .input-box-m1 {
    width: 100% !important;
  }

  /* .input-box-Im {
    flex-direction: column;
 } */
  .table-row2 {
    width: 100% !important;
  }

  .modify_p_text7 {
    top: -18px !important;
  }

  .input-height {
    height: 50px !important;
  }

  .modify_lbl_text2 {
    left: 7.5% !important;
    top: 65px !important;

  }

  .modify_p_text2 {
    top: -6px !important;
  }

  .panel-head-title {
    width: 100% !important;
  }

  .tab-content-1 {
    display: flex;
    flex-direction: column;
  }

  .size-m1 {
    padding-right: 44%;
  }

  .company_info-text {
    display: none;
  }

  .control_master {
    display: flex;
    align-items: center;
    background-color: #5a965a;
    color: white;
    height: 50px;
    justify-content: flex-start;
    border-radius: 5px;
  }

  .modify_lbl_text2 {
    left: 6.8% !important;
    top: 65px !important;
  }

  .modify_lbl_text6 {
    left: 6.8% !important;
    top: 56px !important;
  }

  .ffm_h1 {
    left: 8% !important;
  }

  .modify_lbl_text-si1 {
    left: 9% !important;
    top: 54px !important;
  }

  .input-box-head-fm1 {
    padding-top: 10px !important;
  }

  .tab {
    width: 20% !important;
    font-size: 14px !important;
    text-align: center;
  }

  .m-22 {
    top: -4px !important;
  }

  .col_587452 {
    justify-content: center !important;
  }

  .input-s1 {
    height: 30px !important;
  }

  .ddm1 {
    height: 60px !important;
  }

  .overstack {
    overflow: scroll;
    width: 410px !important;
    height: 100px !important;
  }

  .modify-input-box-part-1 {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .modify-input-box-part-2 {
    width: 30%;
    display: flex;
  }

  .modify-input-box-part-3 {
    width: 20%;
  }

  .i-2 {
    padding-left: 10px;
  }

  .class_col-2 {
    width: 15%;
  }

  .class_col-3 {
    width: 85%;
  }

  .class_h-2 {
    font-size: 18px;
  }

  .class_h-p {
    margin: 0;
    text-align: inherit;
    padding-top: 15px;
  }

  .class_h-date {
    font-size: 11px;
  }

  .company-info-top-left {
    width: 100% !important;
  }

  .company-info-top-im {
    width: 100%;
    flex-direction: column !important;
  }

  .input-box-Image {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-right: 4%;
    margin-bottom: 62px;

  }

  .input-box-Image {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-right: 4%;
    margin-bottom: 62px;

  }

  .modify_pic_text {
    padding-right: 5%;
  }

  .input-box-Image {
    width: 100%;
    padding-left: 80px;
    height: 200px !important;
    position: initial !important;
  }

  .input-height2 {
    width: 100%;
    height: 113px !important;
    margin-bottom: 25px;
    flex-direction: column !important;

  }

  .modify_im_input {
    width: 99% !important;
    height: 45px !important;
  }

  .m-4 {
    display: block !important;
  }

  .input-box-am-1 {
    display: block;
  }

  .sub-input-box {
    height: 50px;
  }

  .input-box1 {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 24px !important;
    height: 48px !important;
  }

  .input-boxupi {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 24px !important;
    height: 300px !important;
  }

  .modify_p_text {
    font-size: 14px;
    margin-bottom: 0px;
    position: absolute;
    left: 4%;
    top: 0%;
    color: #413b3b;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  /* .m2 {
    width: 75% !important;
    height: 45px !important;
  } */
  .m2 {
    width: 200% !important;
    height: 100px !important;
  }

  .m5 {
    width: 30%;
    height: 25px;
    padding-left: 5px;
    font-size: 15px;
  }

  .h7 {
    height: 25px;
  }

  .h8 {
    height: 60px;
  }

  .h9 {
    height: 10px;
  }

  .payment-row {
    padding-top: 50px;
    width: 100% !important;
  }

  .panel-time {
    display: flex !important;
  }

  .buttom-box {
    display: flex !important;
  }

  .m1 {
    width: 100% !important;
  }

  .modify_label_text6 {
    top: -4px !important;
  }

  .panel-time {
    width: 100%;
    font-size: 15px;
    height: 58px;
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid lightgray;
    box-shadow: 2px 2px 4px #cbc7c7;
  }

  .panel-type-p {
    margin-bottom: 0px;
    color: rgb(159 156 156);
  }

  .panel-type-h {
    color: rgb(103 99 99);
  }

  .content-row-1 {
    display: block;
  }

  .item-info-top {
    display: block;
    flex-direction: column !important;
  }

  .company-info-top-left1 {
    padding: 0 !important;
    display: initial;
    width: 100% !important;
  }

  .company-info-top-right {
    width: 100% !important;
    padding: 2px !important;
  }

  .nav-1 {
    display: none;
  }

  .company_info-text {
    display: none;
  }

  .company-info-down {
    width: 100%;
  }

  .panel-heading {
    margin-top: 00px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #212e4f;
    background-color: #212e4f !important;
  }

  .AccountMasterNameLabel {
    left: 160px !important;
    top: -7px !important;
  }

  .Marg18 {
    margin-right: 18px;
  }

  .input:valid~label {
    transform: translateY(-50%) scale(0.9) !important;
  }

  .col_1 {
    display: none !important;

    padding-top: 27%;
  }

  .buttom-box {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    bottom: 0px;
    z-index: 15;
  }

  .user-label07 {
    position: absolute;
    left: 3%;
    color: #e8e8e8;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .input:focus~label,
  input:valid~label {
    transform: translateY(-50%) scale(0.8);
    /* background-color: #ffffff; */
    padding: 0 0.2em;
    /* color: white !important; */
  }

  .button-btn-1 {
    height: 50px;
    display: grid;
    width: 45%;
  }

  .button-btn-2 {
    height: 50px;
    display: grid;
    width: 45%;
  }

  .button-btn-3 {
    height: 50px;
    display: grid;
    width: 10%;
  }

  .btn-2 {
    background-color: #302adf;
    color: white;
    border: 0;
    font-size: 18px;
  }

  .btn-1 {
    background-color: #209220;
    color: white;
    border: 0;
    font-size: 18px;
  }

  .btn-3 {
    border: 0;
  }

  .panel-title {
    font-weight: 600 !important;
    font-size: 17px !important;
    margin: 0% !important;
    color: white !important;
  }

  .ic-1 {
    padding-left: 5px;
  }

  .panel-type-top {
    padding-top: 0px;
    padding-left: 0px;
    width: 33%;
    display: flex;
    border-right: 1px solid #d4cccc;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .input-container {
    display: block !important;
    width: 90%;
    margin: auto;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .input-box {
    display: none !important;
  }

  .panel-title {
    padding-top: 20px !important;
    line-height: 25px;
    padding-left: 10px;
  }

  .company-info-top {
    width: 100%;
  }

  .td_col6 {
    text-align: center !important;
    min-width: 90px;
  }

  .col_1 {
    width: 100%;
    /* padding-left: 336px; */
    margin: auto;
    /* position: fixed; */
    bottom: 15px;
  }

  .content_box {
    border: none !important;
    box-shadow: none !important;
    width: 100% !important;
    height: 100%;
    padding-bottom: 20px !important;
  }

  .reset_btn {
    margin-right: 0px !important;
  }

  .company-info-top-left {
    justify-items: initial;
    width: 100% !important;
    /* background: #ffffff; */
    padding-bottom: 40px;
    padding: 0px !important;
  }

  .modify_product_input {
    width: 100% !important;
    height: 50px !important;
  }

  .im-input {
    width: 68% !important;
    height: 50px !important;
  }

  /* .input_text:focus, input:valid {
    outline: none;
    border: 1.5px solid #abadaf;
    box-shadow: 1px 1px 1px #aba69c;
   
} */
  .modify_p_text {
    position: absolute;
    left: 3%;
    color: #e8e8e8;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: 8px;
    font-size: 16px !important;
  }

  .double_input {
    width: 100% !important;
  }

  .label-box-fix-im {
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: -22px;
    background-color: white;
  }

  .modify_p_text_checkbox {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin: 0;
  }

  .input-box2 {

    padding-left: 0px !important;
    padding-right: 0px !important;

  }

  .displaynone {
    display: none;
  }

  .label-box-fix {
    position: absolute;
    left: 3%;
    color: #e8e8e8;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
    left: 31px;
    top: 8px;
  }

  .inputEffect:focus+.labelEffect1,
  .inputEffect:valid+.labelEffect1 {
    top: -7px !important;
    left: 0px !important;
    font-size: 15px !important;
    line-height: 15px;
  }

  .modify_product_input:focus~label,
  input:valid~label {
    transform: translateY(-5%) scale(0.9) !important;
    /* background-color: #ffffff !important; */
    padding: 0 0.2em !important;
    /* color: #2196f3 !important; */
    left: 3px !important;
  }

  .modify_product_input:focus~label,
  textarea:valid~label {
    transform: translateY(0%) scale(0.9) !important;
    background-color: #ffffff !important;
    padding: 0 0.2em !important;
    /* color: #2196f3 !important; */
  }

  .modify_product_input :focus~label {
    transform: translateY(-60%) scale(0.9) !important;
    background-color: #ffffff !important;
    padding: 0 0.2em !important;
    /* color: #2196f3 !important; */
  }

  .save_btn {
    width: 120px !important;
    margin-right: 1% !important;
    height: 35px !important;
    font-size: 18px !important;
  }

  .reset_btn {
    margin-left: 1% !important;
    width: 100px !important;
    height: 35px !important;
    font-size: 18px !important;
  }

  .content_box {
    margin-top: 0px !important;
  }

  .company_info-text {
    display: flex;
    font-size: 18px !important;
    text-align: left;

    margin: 15px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .company-info-top {
    margin-top: 0 !important;
  }

  .btn-sucess {
    width: 55px !important;
  }

  .col-sm-1 {
    padding-top: 25px;
    padding-left: 26px;
    width: 94%;
  }

  .col-sm-2 {
    padding-left: 60px;
    width: 90%;
    align-items: center;
  }

  /* .t1:focus,
  input:valid {
    outline: none !important;
    border: 1.5px solid #e7dede;
    box-shadow: 1px 2px 5px #dfdfdf !important;
  }
  .user-label {
    position: absolute;
    left: 5%;
    color: #413b3b;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .t1:focus ~ label {
    transform: translateY(-50%) scale(0.8);
    background-color: #ffffff00;
    padding: 0 0.2em;
    color: #000000;
  }
  input:valid ~ label {
    transform: translateY(-50%) scale(0.8) !important;
    background-color: #f5f5f5 !important;
    padding: 0 0.2em;
    color: #696969 !important;
    font-size: 16px;
  } */
  .user_label-1 {
    position: absolute;
    left: 4%;
    top: 0%;
    color: #413b3b;
    pointer-events: none;
    transform: translateY(1rem);
    transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .group {
    display: contents;
  }

  .table {
    height: 75px;
    width: 100%;
    font-size: 13px;
  }

  .col-sm-12 {
    padding-right: 20px;
  }

  /* new-----code */

  .class_control {
    padding-top: 10px;
    padding-right: 5px;
    padding-left: 5px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    height: 102px;
  }

  .balance-1 {
    padding-top: 0;
    border-radius: 8px;
    align-items: center;
    height: 70px;
    background-color: #007bff;
    display: flex;
    color: #fbf8f8;
    width: 30%;
    border: 1px solid lightblue;
    flex-direction: column;
    justify-content: center;
    box-shadow: 2px 5px 5px #b2b2b294;
    align-items: center;
  }

  .balance-p1 {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .icon_3 {
    margin-right: 4px;
  }

  .content-row-1 {
    margin-top: 50px;
  }

  .con_p_3 {
    font-size: 15px;
    font-weight: 600;
    margin: 0;
  }

  .class_control_2 {
    padding-top: 0px;
    padding-right: 5px;
    padding-left: 5px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    height: 60px;
  }

  .parti_1 {
    border-radius: 15px;
    align-items: center;
    height: 40px;
    background-color: #dc3737;
    display: flex;
    color: #fbfbfb;
    width: 31%;
    border: 1px solid lightblue;
    flex-direction: column;
    justify-content: center;
    box-shadow: 2px 5px 5px #b2b2b294;
  }

  .p-11 {
    width: 55%;
  }

  .parti-p-1 {
    margin-bottom: 0px !important;
    font-size: 23px;
  }

  .parti_1:hover {
    box-shadow: 0 0 5px 5px rgba(132, 89, 235, 0.226);
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
  }

  .parti-p-1 {
    color: white;
    font-size: 16px;
  }

  .class_control_3 {
    padding-right: 15px;
    bottom: 20px;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: fixed;
  }

  .col_btn_1 {
    justify-content: center;
    width: 34%;
    display: flex;
    padding-right: 10px;
    flex-direction: row-reverse;
  }

  .payment-btn1 {
    width: 120px;
    height: 40px;
    color: #eff5ef;
    background-color: #007bff;
  }

  .sale-btn2 {
    width: 120px;
    height: 40px;
    color: white;
    background-color: #fe0000;
  }

  .drop-btn3 {
    border-radius: 30px;
    height: 40px;
    width: 40px;
    border-color: #068fff;
    box-shadow: 1px 2px 2px lightgreen;
  }

  .col_ms_1 {
    margin: auto;
    padding-left: 10px;
    justify-content: space-between;
    display: flex;
    font-size: 14px;
    border-bottom: 1px solid lightgray;
    height: 65px;
    align-items: center;
    width: 96%;
  }

  .col_sm_21 {
    display: flex;
    justify-content: center;
    width: 30%;
  }

  .col_sm_13 {
    display: flex;
    justify-content: center;
    width: 30%;
  }

  .col_sm_14 {
    display: flex;
    justify-content: center;
    width: 30%;
  }

  .col_sm_11 {
    border: 1px solid #d9d7d7;
    height: 100px;
    display: flex;
    justify-items: center;
    justify-content: space-between;
    box-shadow: 1px 2px 5px #dfdfdf;
    padding: 10px;
    margin: auto;
    border-radius: 12px;
    margin-top: 20px;
    width: 96%;
  }

  .con_name_1 {
    padding-right: 0px;
    padding-top: 0px;
    font-size: 18px;
    display: flex;
    height: 20px;
    justify-content: space-between;
    align-items: center;
  }

  .con_p_1 {
    display: flex;
    font-size: 10px;
    padding-bottom: 18px;
    width: 27px;
    color: white;
    border-radius: 4px;
    height: 5px;
    background-color: #1a5d1abd;
    justify-content: center;
    margin: 0;
  }

  .balance-p2 {
    margin: 0px;
  }

  .col_sm_12 {
    padding-left: 0px;
  }

  .con_name_7 {
    width: 80px;
    display: flex;
    justify-content: space-between;
  }

  .con_sale_2 {
    height: 29px;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }

  .con_total_3 {
    margin-top: 0px;
    display: flex;
    justify-content: center;
    font-size: 14px;
  }

  .company-info-top-right2 {
    width: 95%;
    padding-bottom: 27% !important;
  }

  .con_p_4 {
    font-size: 14px;
    margin: 0;
  }

  .con_p_2 {
    font-size: 12px;
    color: #675f5f;
    margin: 0;
  }

  .rs_sign {
    color: white;
  }

  .rs_sign1 {
    color: #4f4848;
    margin-right: 4px;
  }

  .p_13 {
    margin: 0px;
    font-size: 16px;
  }

  .con_price_p {
    padding-top: 11px;
  }

  .input_box_2 {
    font-size: 17px !important;
    height: 40px;
    width: calc(100% - 4px);
    /* height: 100%; */
    border: 0;
    border-color: transparent;
    font-size: 1rem;
    padding-right: 0px;
    color: var(--input-line);
    background: var(--input-bg-color);
    border-right: 2px solid var(--input-border-color);
    outline: none;
  }

  /*************MH Cheff****************** */
  .MH-Box {
    width: 27% !important;
    flex-direction: column;
    padding: 10px !important;
    height: max-content;
  }

  .Flex-T-MH {
    display: flex;
    justify-content: space-around;
  }

  .Flex-P-MH {
    display: flex;
    justify-content: space-around;

  }

  .fs-121-MH {
    margin-top: 5px;
  }

  /*************Item Master****************** */
  .bulk-upload-p {
    margin-left: 7px;
    margin-bottom: 7px !important;
  }

  .td-2 {
    padding-left: 0 !important;
  }

  .company_info-text-im {
    align-items: center !important;
  }

  .radio-btm-m3 {
    padding-left: 15px !important;
  }

  .DirectWidth {
    width: 93% !important;
  }

  .ItemMasterLabelFix {
    top: 0px !important;
  }

  .Item-Service-Pro {
    display: block !important;
    width: 120px !important;
  }

  .ItemGeneralSettingCol {
    justify-content: flex-end;
    margin-right: 0px !important;
  }

  .ModalBoxItem_FullHeight {
    overflow: scroll !important;
    max-height: 72vh !important;
  }

  .ModalBoxItem_FullHeight::-webkit-scrollbar {
    display: none;
  }

  .modal-content2 {
    margin: 15% auto !important;
  }


}

@media(max-width:549px) {

  .width100mobile {
    width: 100% !important;
  }

  .width50mobile {
    /* width: 50% !important; */
  }

  .width75mobile {
    width: 75% !important;
  }

  .btn-lavender {
    width: 60px !important;
    height: 40px !important;
    margin: 0px 5px 0px 2px !important;
  }

  .table-left-padding {
    padding-top: 10px !important;
  }


  .padding-20 {
    padding: 5px 15px !important;
  }



  .tab-content-1 {
    display: block !important;
  }

  .stock-label {
    display: inline-block !important;
    /* justify-content: space-between;
    align-items: flex-start; */
    margin-bottom: 5px;
  }

  .showMobile {
    display: flex !important;
  }

  .hideInMobile {
    display: none !important;
  }

  .height25vh {
    height: auto !important;
  }


  .height30vh .table-row1 {
    border: none !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .height30vh .tab_a1 {
    border: none !important;
  }

  .input-box-m6 div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .height30vh {
    height: auto !important;
    max-height: max-content !important;
    border: none !important;
  }

  .tab-btn-box1 {
    padding: 00;
  }

  .tab-content-box {
    display: block !important;
    align-items: flex-start !important;
    justify-content: flex-start;
  }

  .input-box-head {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    margin-bottom: 5px;
  }

  .modify_im_input {
    height: 40px !important;
  }

  .input-box-SI {
    /* justify-content: flex-start !important; */


  }

  .input-box-s12 {
    width: 43% !important;
    justify-content: center;
  }

  .labelEffect1 {
    position: absolute;
    top: 10px !important;
    left: 8px !important;
    transition: top 0.2s, font-size 0.2s;
    pointer-events: none;
    background-color: white;
    color: #6a6161 !important;
    font-size: 14px;
  }


  .navbar-right {
    display: none !important;

  }

  .input-height2 {
    height: auto !important;
    flex-direction: row !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .flex-end .fa-square-plus {
    font-size: 25px !important;
  }

  .flex-end .fa-rotate-left {
    font-size: 25px !important;
  }

  .inDays {
    width: 50%;
  }

  .width-20 {
    width: 20% !important;
  }

  .ddm1 {
    height: auto !important;
  }

  .m-22 {
    top: -10px !important;
  }

  .input-remark-m1 {
    display: flex;
    flex-direction: column;
  }

  .column-550 {
    display: flex;
    flex-direction: column;
    width: 170% !important;
  }

  .width-100 {
    width: 100%;
  }

  .bg-color {
    background-color: #5e709e !important;
  }

  .c-white {
    color: white;
    font-size: 13px;
    font-weight: 600;
  }

  .c-b {
    color: black !important;
  }

  .active label {
    color: black !important;
  }

  .table-th-1 {
    width: 6% !important;
  }

  .table-down-s2 {
    display: flex !important;
    width: 100% !important;
    color: indianred;
    align-items: center;
  }

  .color-col-s1 {
    margin: 5px !important;
  }

  .color-col-s3 {
    margin: 5px !important;
  }

  .color-col-s4 {
    margin: 5px !important;
  }

  .input-box-m7 {
    flex-direction: column;
  }

  .height-115 {
    height: 115px;
  }

  .search-panel-col-1 {
    flex-direction: column;
  }

  .td {
    font-size: 14px;
  }

  .tableHeader {
    font-size: 14px;
    font-weight: 500;
    color: darkgray;
  }

  .totalBg-color {
    background-color: white !important;
  }

  .pwrInput {
    font-size: 12px !important;
    padding: 3px !important;
  }

  .input_text {
    font-size: 12px !important;
  }

  .settingIcon {
    display: none !important;
  }


  .input-box-SI {
    margin-bottom: 15px;
  }

  .saleRemarkPart {
    flex-direction: column;
  }

  .mobileFlex-direction {
    flex-direction: column;
    width: 95% !important;
    margin: auto !important;
  }

  .lensInfoLeft-box {
    width: 100% !important;
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .w-100-48 {
    width: 49% !important;
  }

  .w-25 {
    width: 100%;
  }

  .lensInfoRight-box {
    width: 100% !important;
  }

  .col-sm-5 {
    display: flex;
    justify-content: center;
    width: 44% !important;
  }

  .w-35 {
    width: 42% !important;
  }

  .partyDetail-modal {
    width: 80% !important;
  }

  #saleRemarkPart {
    padding-bottom: 30px;
  }

  .input-box-top-head {
    justify-content: center;
    padding: 3px 8px 0px 0px !important;
  }

  .tab-2 {
    width: 33.33% !important;
  }

  .input-box-head-1 {
    /* padding: 5px; */
    /* padding-bottom: 20px; */
    padding: 10px 5px 7px 0px;
  }

  .InvoiceLensSettingicon {
    position: absolute !important;
    left: 6px !important;
    font-size: 15px !important;
    top: 17px !important;
  }


  .PopforSelectingLens {
    width: 100% !important;
    height: 330px !important;

  }

  .PopforSelectingLens_Loop {
    height: max-content !important;
    padding-bottom: 10px;
  }


  .popup-back {

    background: rgb(0 0 0 / 16%);

  }

  .sale-in-lens-mobile {
    flex-direction: row;
  }

  .sale-in-lens-mobile div {
    width: 100% !important;
  }

  .ItemShowingTabMobileBox {
    display: flex !important;
  }

  .ItemmasterDuggestionsaleinvoice {
    width: 100% !important;
  }

  .table-th-6 {
    /* padding-left: 16px;
    padding-right: 10px; */
    text-align: center;
    width: 190px !important;
    font-size: 11px !important;
  }

  .table-th-5 {
    /* padding-left: 16px;
    padding-right: 10px; */
    text-align: center;
    width: 110px !important;
    font-size: 11px !important;
  }

  .table-th-4 {
    /* padding-left: 16px;
    padding-right: 10px; */
    text-align: center;
    width: 60px;
    font-size: 11px !important;
  }

  .td-col2 {
    width: 190px !important;
    text-align: center;
    font-size: 11px !important;
  }

  .xmark-ms-2 {
    bottom: 340px !important;

  }


  .saleinvoicetop-input-container {
    width: 48%;
    position: relative;
  }


  .margin-25-mob {
    margin-top: 12px;
  }


  .saleinvoicetop-input-container input[type="text"],
  .saleinvoicetop-input-container input[type="number"],
  .saleinvoicetop-input-container input[type="date"] {
    height: 40px !important;
  }

  .saleinvoicetop-input-container select {
    height: 40px !important;
  }


  .saleinvoicetop-input-container .saleinvoicetop-label {
    top: 9px !important;
    font-size: 15px !important;
  }


  .saleinvoicetop-input-container select:focus~.saleinvoicetop-label,
  .saleinvoicetop-input-container select:valid~.saleinvoicetop-label {
    top: -8px !important;
    font-size: 15px !important;
    background-color: transparent !important;

  }

  .saleinvoicetop-input-container input[type="text"]:focus~.saleinvoicetop-label,
  .saleinvoicetop-input-container input[type="text"]:valid~.saleinvoicetop-label,
  .saleinvoicetop-input-container input[type="number"]:focus~.saleinvoicetop-label,
  .saleinvoicetop-input-container input[type="number"]:valid~.saleinvoicetop-label,
  .saleinvoicetop-input-container input[type="date"]:focus~.saleinvoicetop-label,
  .saleinvoicetop-input-container input[type="date"]:valid~.saleinvoicetop-label {
    top: -8px !important;
    font-size: 15px !important;
    background-color: transparent !important;
  }

  .saleinvoicetop-input-container select {
    font-size: 15px !important;

  }

  .saleinvoicetop-input-container input[type="text"],
  .saleinvoicetop-input-container input[type="number"],
  .saleinvoicetop-input-container input[type="date"] {
    font-size: 15px !important;
  }

  .LensSelectioninvoice {
    height: 20px;
  }

}