/*Sidebar.css*/
.sidebar_side {
  width: 60px;
  height: 98vh;
  background-color: #edeff4;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: width 0.3s;
  border: 1px solid #9f9f9f;
  z-index: 2;
  padding: 10px;
  border-radius: 4px;
  /*overflow: -moz-hidden-unscrollable;*/
  scrollbar-width: none;
}

.sidebar_side::-webkit-scrollbar {
  display: none;
}

.sub-category-2 {
  padding-left: 1px;
  background: #dfdcdc;
}

.subCategory-sideBar {
  background-color: transparent;
}

.sidebar_side:hover {
  width: 230px;
  background-color: #edeff4;
  color: black;
}

.sidebar_side.expanded {
  width: 230px;
  background-color: #edeff4;
  color: black;
}

.sidebar_side.expanded::-webkit-scrollbar {
  display: none;
}

.toggle-button1 {
  cursor: pointer;
  padding: 15px;
  color: white;
  text-align: left;
  font-size: 18px;
  background-color: #101010;
  /* width: 6.5%;
    z-index: 2;*/
  position: fixed;
  top: 0%;
  left: 0%;
  width: 230px;
  display: flex;
}

.nav-item {
  width: 230px;
  padding: 8px 9px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /*border-bottom: 1px solid #dcd3d3;*/
}

.nav-item:hover i {
  color: white;
}

.nav-item:hover h6 {
  color: white;
}

.nav-item-heading {
  width: 230px;
  padding: 11px 9px;
  border-bottom: 1px solid #dcd3d3;
  background-color: #212e4f;
  height: 48px;
  margin-top: -10px;
  margin-left: -9px;
  /*position:absolute;*/
}

/*Link{
    text-decoration:none;
}*/

.nav-sub-item {
  width: 230px;
  /* padding: 7px 3px; */
  background-color: transparent;
  margin-left: 28px;
  align-items: center;
  padding: 10px;
}


.sideBar-sub-text {
  text-decoration: none;
  margin: 0;
  margin-left: 8px;
  font-size: 15px;
  font-weight: 600;
  color: black;
}

.sideBarIcon {
  color: black;
  padding-left: 0px;
}

.sideBarIcon:hover {
  color: white;
}

.sideBarIcon-sub {
  color: black;
  padding-left: 0px;
  font-size: 16px !important;
}

.nav-sub-item:hover {
  background-color: #212e4f
}

.nav-sub-item:hover .sideBar-sub-text {
  color: white;
}

.nav-sub-item:hover i {
  color: white;
}

.bg_hover:hover {
  display: flex;
  background-color: #32477d;
  border-radius: 10px;
  /*padding-bottom: 5px;*/
  padding: 0px 9px;
}

.sideBar-heading {
  text-decoration: none;
  padding-left: 18px;
  font-size: 19px;
  font-weight: 600;
  color: white;
  margin-top: 1px;

}

.sideBar-text {
  text-decoration: none;
  padding-left: 18px;
  font-size: 18px;
  font-weight: 500;
  margin: 5px 0px;
}

.sideBar-text:hover {
  color: white;
  font-weight: 500;
}

.nav_padding {
  padding: 7px 5px;
  align-items: baseline;
}

.scroll-container {
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  /*height: 200px;*/
  transition: scrollbar-color 0.5s ease;
}

.scroll-container::-webkit-scrollbar {
  width: 1px;
}

.left-container {
  height: 100vh;
  background-color: transparent;
  color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: width 0.3s;
}

.left-container.expanded {
  width: 230px;
}

.right-container {
  flex: 1;
  /* padding: 5px; */
  /* background-color: #f0f0f0; */
  transition: margin-left 0.3s;
}

.app-container {
  display: flex;
}


/*******************************************/
/* Sidebar.css */
/*.sidebar_side {
    width: 60px;
    height: 98vh;
    background-color: #edeff4;
    position: fixed;
    top: 0  ;
    left: 0;
    overflow-x: hidden;
    transition: width 0.3s;
    border: 1px solid #9f9f9f;
    z-index: 2;
    padding: 5px;
    border-radius: 7px;
  
}

.sub-category-2 {
    padding-left: 1px;
    background: #dfdcdc;
}
.sideBar-subCategory{
    background-color: aliceblue;
}

    .sidebar_side:hover {
        width: 200px;
        background-color: #edeff4;
        color: black;
    }

    .sidebar_side.expanded {
        width: 200px;
        background-color: #edeff4;
        color: black;
      
    }
    .sidebar_side::-webkit-scrollbar {
        display: none;
      }

.toggle-button1 {
    cursor: pointer;
    padding: 15px;
    color: white;
    text-align: left;
    font-size: 18px;
    background-color: #101010;*/
/*width: 6.5%;*/
/*z-index: 2;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 200px;
    display: flex;
}
.sideBarIcon {
    color: black;
    padding-left: 15px;
}
.sideBarIcon:hover{
    color: white;
}
.nav-item{
    width: 200px;
    padding: 12px 0px;
    display: flex;
    align-items: center;*/
/* border-bottom: 1px solid #dcd3d3; */
/*}
.nav-item:hover i{
    color: white;
}
.nav-item:hover h6{
    color: white;
}
.nav-item-heading {
    width: 200px;
    padding: 15px 8px;*/
/* border-bottom: 1px solid #dcd3d3; */
/*background-color: #054205;
    height: 50px;
    margin-top: -5px;
    margin-left: -6px;
}
.nav-sub-item {
    width: 200px;
    padding: 5px 0px;
    background-color:#dbe1ef;
    

}
.bg_hover:hover{
    display: flex;
    background-color: #32477d;
    border-radius: 10px;*/
/* padding-bottom: 5px; */
/*}

.sideBar-text{
    text-decoration: none;
    padding-left: 25px;
    font-size:18px;
    font-weight: 500;
}*/
/* .sideBar-text:hover{
    color:white;
    font-weight: 500;
} */
/*.left-container {
    height: 100vh;
    background-color: transparent;
    color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: width 0.3s;
}

    .left-container.expanded {
        width: 200px;
    }

.right-container {
    flex: 1;
    padding: 5px;
    background-color: #f0f0f0;
    transition: margin-left 0.3s;
}
.app-container {
    display: flex;
}*/


/* ************************{Second SideBar}************************** */

.sidebar-yesbm {
  width: 55px;
  background-color: rgb(33, 46, 79);
  color: white;
  height: max-content;
  position: fixed;
  left: 0;
  z-index: 6;
  transition: width 0.3s;
  border: 1px solid #a1a1a1;
  border-top: 0;
  position: fixed;
  top: 0;
}

.sidebar-yesbm:hover {
  width: 260px;
}

.sidebar-yesbm-logo {
  height: 54px;
  border-bottom: 1px solid #6e6e6e;
  background-color: #dfdfdf;
  overflow: hidden;
}

.sidebar-yesbm-logo img {
  width: 160px;
  margin-left: 6px;
  margin-top: 0px;
}

.sidebar-yesbm-open-btn {
  /* width: 58px; */
  overflow: hidden;
  height: 53px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-bottom: 1px solid #6e6e6e;
}

.sidebar-yesbm-open-btn div {
  display: flex;
  align-items: center;
}

.sidebar-yesbm-bottom {
  background-color: #edeff4;
  height: 100vh;
  /*max-content;*/
  overflow: hidden;
}

/* .sidebar-yesbm-bottom-input-box {
  } */

.sidebar-yesbm-bottom-input {
  border-radius: 8px;
  padding: 2px 7px;
  width: 190px;
}

.sidebar-yesbm-bottom-scroll-box {
  overflow: scroll;
  height: 85vh;
  padding-bottom: 15px;
  scrollbar-width: none;
}

.sidebar-yesbm-bottom-scroll-box::-webkit-scrollbar {
  display: none;
}

.sidebar-yesbm-bottom-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  padding: 9px 0px;
  color: black;
  font-weight: 600;
  border-bottom: 1px solid rgb(211, 211, 211);
  height: 40px;
  width: 260px;
}

.sidebar-yesbm-bottom-option:hover {
  border-left: 5px solid #212e4f;
  background-color: #bebebe;
  cursor: pointer;
}

.sidebar-yesbm-bottom-option p {
  margin-bottom: 0;
}

.sidebar-yesbm-bottom-option img {
  width: 22px;
  margin-right: 18px;
  margin-left: 16px;
}

.sidebar-yesbm-bottom-option div {
  display: flex;
  align-items: center;
}

.sidebar-yesbm-bottom-option i {
  width: 10px;
  margin-right: 10px;
  font-size: 14px;
  margin-top: 2px;
}

.icon-menu {
  --gap: 5px;
  --height-bar: 2px;
  --pos-y-bar-one: 0;
  --pos-y-bar-three: 0;
  --scale-bar: 1;
  --rotate-bar-one: 0;
  --rotate-bar-three: 0;
  width: 22px;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  cursor: pointer;
  position: relative;
  /* margin-top: -24px; */
  margin-right: 15px;
  margin-left: 3px;
}

.bar {
  position: relative;
  height: var(--height-bar);
  width: 100%;
  border-radius: 0.5rem;
  background-color: #fcfcfc;
}

.bar--1 {
  top: var(--pos-y-bar-one);
  transform: rotate(var(--rotate-bar-one));
  transition: top 200ms 100ms, transform 100ms;
}

.bar--2 {
  transform: scaleX(var(--scale-bar));
  transition: transform 150ms 100ms;
}

.bar--3 {
  bottom: var(--pos-y-bar-three);
  transform: rotate(var(--rotate-bar-three));
  transition: bottom 200ms 100ms, transform 100ms;
}

.check-icon {
  opacity: 0;
}

.check-icon:checked+.icon-menu>.bar--1 {
  transition: top 200ms, transform 200ms 100ms;
}

.check-icon:checked+.icon-menu>.bar--3 {
  transition: bottom 200ms, transform 200ms 100ms;
}

.check-icon:checked+.icon-menu {
  --pos-y-bar-one: calc(var(--gap) + var(--height-bar));
  --pos-y-bar-three: calc(var(--gap) + var(--height-bar));
  --scale-bar: 0;
  --rotate-bar-one: 45deg;
  --rotate-bar-three: -45deg;
}

/* FIN ICON-MENU */

.expanded {
  width: 260px;
}

.yes-bm-sub-category {
  visibility: hidden;
  height: 0;
  margin-left: 12px;
  border-left: 1px solid rgb(100, 100, 100);
  opacity: 0;
  transition: all;
  transition-duration: 300ms;
  /* display: none; */
}

.yes-bm-sub-category-main {
  visibility: hidden;
  height: 0;
  margin-left: 25px;
  border-left: 1px solid rgb(100, 100, 100);
  opacity: 0;
  position: absolute;

}

.show-sub-cate {
  visibility: initial;
  opacity: 1;
  background-color: #ececec;
  flex-direction: column;
  opacity: 1;
  display: flex;
  height: auto;
  position: initial;
}



.show-child-cate {
  visibility: initial;
  opacity: 1;
  background-color: #e1e1e1;
  flex-direction: column;
  opacity: 1;
  display: flex !important;
  /* margin-left: 10px; */
  font-size: 14px;
}

.hf2 {
  height: 70px;
}

.hf3 {
  height: 105px;
}

.hf4 {
  height: 140px;
}

.hf5 {
  height: auto;
}

.hf6 {
  height: 210px;
}

.hf7 {
  height: 245px;
}

.hf8 {
  height: 280px;
}

.hf9 {
  height: auto;
}

.hf10 {
  height: 350px;
}

.hf11 {
  height: 385px;
}

.hf12 {
  height: 420px;
}

.hf13 {
  height: 455px;
}

.hf15 {
  height: 525px;
}

.yes-bm-sub-category-box {
  width: 232px;
  text-decoration: none;
  font-size: 14px;
  height: 35px;
  display: flex;
  align-items: center;
  color: #212e4f;
  position: relative;
  font-weight: 600;
  padding-left: 27px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(231, 231, 231);

}

.yes-bm-sub-category-box::before {
  width: 10px;
  height: 0;
  border-right: 0;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  left: 0px;
  top: 15px;
  position: absolute;
  z-index: 2;
  content: "";
  display: inline-block;
  color: hsl(0, 0%, 0%);
}

.yes-bm-sub-category-box .fixed-arrow {
  width: 10px;
  margin-right: 10px;
  font-size: 13px;
  margin-top: 0px;
}

.yes-bm-sub-category-box .fixed-arrow :hover {
  background-color: black;
}

.yes-bm-sub-category-box .floting-arrow {
  width: 10px;
  font-size: 13px;
  position: absolute;
  left: 8px;
  top: 10px;
}

.yes-bm-sub-category-box:hover {
  border-left: 2px solid #212e4f;
  cursor: pointer;
  background-color: #d1d1d1;
  color: black;
}



.yes-bm-child-category-box {
  width: 220px;
  line-height: 16px;
  text-decoration: none;
  font-size: 13px;
  height: 35px;
  display: flex;
  align-items: center;
  font-family: inherit;
  color: black;
  position: relative;
  font-weight: 600;
  padding-left: 20px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(231, 231, 231);
}

.yes-bm-child-category-box::before {
  width: 10px;
  height: 0;
  border-right: 0;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  left: 0px;
  top: 15px;
  position: absolute;
  z-index: 2;
  content: "";
  display: inline-block;
  color: hsl(0, 0%, 0%);
}

.yes-bm-child-category-box .fixed-arrow {
  /* width: 13px; */
  margin-right: 5px;
  font-size: 13px;
  margin-top: 0px;
  display: flex;
  z-index: 1;
  text-decoration: none;
  color: black;
  justify-content: center;
  padding: 5px;
  border-radius: 50%;
}

.yes-bm-child-category-box .fixed-arrow:hover {
  background-color: rgb(114, 114, 114);
  color: white;
}

.yes-bm-child-category-box .floting-arrow {
  width: 10px;
  font-size: 11px;
  position: absolute;
  left: 8px;
  top: 10px;
}

.yes-bm-child-category-box:hover {
  border-left: 3px solid rgb(0 92 190);
  cursor: pointer;
  scale: 1.01;
  color: black;
  background-color: #edeff4;
}

.sideBar_LogOut {
  color: white;
  margin-right: 10px;
  font-size: 18px;
  margin-top: 10px;
  background-color: black;
  padding: 8px;
  height: 35px;
  border-radius: 5px;
}

/* ************************{Second SideBar End}************************** */